.tableScrollContainer {
  overflow-y: auto;

  @media screen and (max-width: 991px) {
    padding: 25px 15px 50px;
  }

  @media screen and (min-width: 992px) {
    height: calc(100vh - 200px);
    margin-right: -20px;
    padding-right: 10px;
    padding-bottom: 50px;
  }

  .statusContainer {
    background: #3d734a;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 5px;
      color: #fff;
    }

    .subtitle {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.5);
    }

    svg {
      margin-left: 20px;
    }

    .openQrCode {
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      padding-top: 20px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .totalsContainer {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    ul {
      background: #e6e6e6;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        p {
          color: #666666;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          &.benefit {
            font-weight: 600;
            color: #3d734a;
          }
        }

        span {
          color: #666666;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;

          &.benefit {
            font-weight: 700;
            color: #3d734a;
          }

          &.total {
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }

    .gift {
      background: rgba(61, 115, 74, 0.15);
      border-radius: 30px;
      display: flex;
      align-items: center;
      padding: 5px 10px;

      .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        background: #b7c6bb;
        border-radius: 50%;

        svg path {
          stroke: #3d734a;
        }
      }

      p {
        color: #3d734a;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        strong {
          font-weight: 700;
        }
      }
    }
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 250px);
    padding: 10px 15px;

    .icon {
      background: rgba(61, 115, 74, 0.1);
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      border-radius: 20px;

      svg {
        width: 32px;
        height: 28px;

        path {
          stroke: #3d734a;
        }
      }
    }

    p {
      color: #404040;
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }

    span {
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.footer {
  padding: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
