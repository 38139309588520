.menuMobile {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
  z-index: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: -5px -5px 20px 15px rgba(132, 132, 132, 0.1);

  @media screen and (min-width: 992px) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 5px;

      &.active {
        .name {
          color: #3d734a;
        }

        svg path {
          fill: #3d734a;
          stroke: #3d734a;
        }
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 20px;

        .quantity {
          position: absolute;
          z-index: 1;
          bottom: 3px;
          right: -10px;
          min-width: 20px;
          line-height: 12px;
          font-size: 10px;
          background: #3d734a;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 400px;
          color: #fff;
        }

        .ordersInProgress {
          position: absolute;
          right: 0;
          top: 0;
          background: #3d734a;
          border-radius: 400px;
          font-size: 10px;
          line-height: 12px;
          color: #f7f7f7;
          display: flex;
          min-width: 20px;
          min-height: 20px;
          padding: 2.5px;
          align-items: center;
          justify-content: center;
        }
      }

      .name {
        color: rgba(132, 132, 132, 0.75);
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        margin-top: 5px;
      }
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}
