.cart {
  height: 100%;

  .totals {
    background: #ffffff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 20px;
    margin-bottom: 15px;

    span {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }

    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #666666;
    }
  }

  .scrollContainer {
    height: calc(100% - 100px);
    overflow-y: auto;
    width: 100%;
    padding: 20px;

    .deliveryContainer {
      background: #ffffff;
      box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      cursor: pointer;

      .deliveryForecast {
        font-weight: 800;
        font-size: 16px;
        line-height: 22px;
        color: #595959;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      .deliveryType {
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: #3d734a;
        margin-bottom: 5px;
      }

      .icon {
        min-width: 76px;
        min-height: 76px;
        border-radius: 15px;
        margin-right: 20px;
        position: relative;
      }

      .selectAddress {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #595959;
      }

      .deliveryIn {
        span {
          font-size: 14px;
          line-height: 22px;
          color: rgba(89, 89, 89, 0.65);
          margin-bottom: 5px;
          display: block;
        }

        .address {
          font-weight: 700;
          font-size: 14px;
          line-height: 22px;
          color: #595959;

          p {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }

        .unitName {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: rgba(102, 102, 102, 0.7);
          margin-top: 5px;
        }
      }

      .referencePoint {
        font-size: 14px;
        line-height: 22px;
        color: rgba(89, 89, 89, 0.65);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        margin-top: 5px;
      }
    }

    .benefitContainer {
      background: #ffffff;
      border-radius: 15px;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
      cursor: pointer;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #4d4d4d;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      .gift {
        background: rgba(164, 164, 164, 0.12);
        border-radius: 15px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        @media screen and (max-width: 380px) {
          padding: 5px;
          margin-right: 10px;
        }

        &.applied {
          border: 1.5px dashed rgba(61, 115, 74, 0.21);
          background: rgba(61, 115, 74, 0.12);

          .icon {
            background: rgba(61, 115, 74, 0.1);

            svg path {
              fill: #3d734a;
            }
          }
        }

        .icon {
          background: rgba(140, 140, 140, 0.1);
          border-radius: 10px;
          width: 40px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 380px) {
            width: 28px;
          }
        }
      }

      .benefitApplied {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(0, 0, 0, 0.35);

        &.applied {
          padding: 5px 15px;
          background: rgba(61, 115, 74, 0.07);
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 800;
          font-size: 14px;
          line-height: 18px;
          color: rgba(61, 115, 74, 0.7);
        }
      }
    }

    .paymentContainer {
      background: #ffffff;
      border-radius: 15px;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);
      cursor: pointer;

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #4d4d4d;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      .selectPayment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selectPaymentMethod {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.35);
        }

        .pix {
          width: 32px;
          height: 32px;
          position: relative;
          margin-right: 20px;
        }

        .icon {
          min-width: 47px;
          min-height: 32px;
          position: relative;
          margin-right: 15px;
          background: rgba(240, 240, 240, 0.66);
          border: 1px solid rgba(0, 0, 0, 0.03);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          @media screen and (max-width: 380px) {
            min-width: 32px;
          }
        }

        .paymentName {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: rgba(89, 89, 89, 0.9);
        }

        .cardNumber {
          font-size: 14px;
          line-height: 18px;
          color: rgba(89, 89, 89, 0.7);
          margin-top: 5px;
        }
      }
    }

    .totalContainer {
      background: #ffffff;
      border-radius: 15px;
      padding: 20px;
      box-shadow: 0px 0 30px 10px rgb(153 153 153 / 10%);

      .title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #4d4d4d;
        padding-bottom: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid #e6e6e6;
      }

      .total {
        p {
          font-size: 14px;
          line-height: 25px;
          color: rgba(115, 115, 115, 0.75);
        }

        .gift {
          color: #3d734a;
          font-weight: 500;
        }

        .giftCode {
          font-weight: 500;
          font-size: 12px;
          color: rgba(128, 128, 128, 0.65);
        }

        .giftValue {
          margin-left: 10px;
          font-weight: 700;
          color: #3d734a;
          font-size: 14px;
        }

        .value {
          font-size: 14px;
          line-height: 25px;
          color: rgba(115, 115, 115, 0.75);
        }

        .totalValue {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 7px;

          p {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #666666;
          }

          .value {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #666666;
          }
        }
      }
    }
  }
}

.errorMessage {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #333333;
}

.pixCode {
  background: #ffffff;
  box-shadow: 0px 8px 68px 45px rgba(153, 153, 153, 0.09);
  padding: 20px;
  border-radius: 10px;

  .pix {
    background: #f2f2f2;
    border-radius: 20px;
    width: 43px;
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    position: relative;
  }

  .status {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #595959;
  }

  .hairline {
    background: #e6e6e6;
    height: 1px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .clock {
    background: rgba(61, 115, 74, 0.15);
    border-radius: 10px;
    width: 42px;
    height: 42px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .time {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #595959;
  }

  .instructions {
    margin-top: 15px;
    padding-left: 15px;

    li {
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      color: #595959;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media screen and (min-width: 992px) {
  }
}

.confirmOrder {
  .deliveryContainer {
    margin-bottom: 15px;

    .icon {
      min-width: 76px;
      min-height: 76px;
      border-radius: 15px;
      margin-right: 20px;
      position: relative;
    }

    .selectAddress {
      font-weight: 700;
      font-size: 14px;
      line-height: 22px;
      color: #595959;
    }

    .deliveryIn {
      span {
        font-size: 14px;
        line-height: 22px;
        color: rgba(89, 89, 89, 0.65);
        margin-bottom: 5px;
        display: block;
      }

      .address {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #595959;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }

      .unitName {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(102, 102, 102, 0.7);
        margin-top: 5px;
      }
    }

    .referencePoint {
      font-size: 14px;
      line-height: 22px;
      color: rgba(89, 89, 89, 0.65);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 5px;
    }

    .deliveryType {
      background: rgba(61, 115, 74, 0.06);
      border-radius: 15px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      .name {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        color: #3d734a;
      }

      .deliveryEstimate {
        border: 1px solid rgba(61, 115, 74, 0.25);
        border-radius: 12px;
        font-size: 12px;
        line-height: 22px;
        color: #3d734a;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 15px;
        font-weight: 500;
      }
    }
  }

  .paymentContainer {
    margin-bottom: 20px;

    .iconCard {
      min-width: 76px;
      min-height: 76px;
      position: relative;
      margin-right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      .card {
        min-width: 47px;
        min-height: 32px;
        background: rgba(240, 240, 240, 0.66);
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .iconPix {
      min-width: 76px;
      min-height: 76px;
      position: relative;
      margin-right: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .pix {
        width: 32px;
        height: 32px;
        position: relative;
      }
    }

    .paymentType {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 5px;
    }

    .paymentName {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
    }

    .total {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #999999;
      margin-right: 10px;
    }
  }
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.withdrawalType {
  background: #e4e8e5;
  border-radius: 15px;
  padding: 3px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #3d734a;
}

.appointmentRequired {
  background: rgba(217, 22, 4, 0.07);
  border-radius: 15px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    color: rgba(238, 64, 35, 0.45);
    margin-left: 10px;
  }
}

.sendingOrder {
  .brandHeader {
    border-radius: 0 0 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 20px;
    background: #ebebeb;
    margin-bottom: 30px;
    min-height: 200px;

    .picture {
      width: 100px;
      height: 100px;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      img {
        object-fit: contain;
      }
    }
  }

  .statusContainer {
    p {
      color: #404040;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
    }

    .msgOne {
      color: #404040;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      opacity: 1;
      transform: translateY(10px);
      animation: toTop 5s ease 2.5s infinite;
    }

    .msgTwo {
      color: #404040;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      opacity: 0;
      transform: translateY(10px);
      animation: toBottom 5s ease 2.5s infinite;
    }
  }

  .loader {
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    height: 3px;
    position: relative;
    background: #d6d6d6;
    overflow: hidden;
    margin-top: 20px;

    &::after {
      content: "";
      width: 200px;
      height: 3px;
      background: #aaa;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      animation: animloader 3s ease 0.1s infinite;
    }
  }
}

@keyframes animloader {
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes toBottom {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  33% {
    opacity: 1;
    transform: translateY(-10px);
  }
  66% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}

@keyframes toTop {
  0% {
    opacity: 1;
    transform: translateY(10px);
  }
  33% {
    opacity: 0;
    transform: translateY(0);
  }
  66% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(10px);
  }
}

.emptyCart {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 25px;

  p {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    color: #404040;
  }
}