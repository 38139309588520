.applyBenefitOptions {
  overflow-y: auto;
  height: calc(100% - 100px);

  @media screen and (min-width: 992px) {
    padding: 35px 50px 0;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .options {
    margin-top: 25px;

    .option {
      border-radius: 30px;
      padding: 30px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @for $i from 1 through 3 {
        &:nth-child(1n + #{$i}) {
          animation: animateTopTop 0.5s both $i * 0.15s;
        }
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.selected {
        background: rgba(61, 115, 74, 0.15);
        border: 1px solid transparent;

        .icon {
          background: rgba(61, 115, 74, 0.15);

          svg {
            width: 30px;
            height: 30px;

            path {
              fill: rgba(61, 115, 74, 1);
            }
          }
        }

        .name {
          color: #3d734a;
        }
      }

      .icon {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: #f0f0f0;
        margin-bottom: 20px;

        svg {
          width: 24px;
          height: 24px;
          transition: all 0.25s ease;

          path {
            fill: #a0a0a0;
          }
        }
      }

      .name {
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        color: #4d4d4d;
      }
    }
  }
}

.finishTableOptions {
  .options {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 35px;
    padding-top: 35px;

    .option {
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.selected {
        border: 1px solid rgba(61, 115, 74, 0.35);

        .icon {
          background: rgba(61, 115, 74, 0.15);

          svg path {
            fill: #3d734a;
          }
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        background: #f0f0f0;
        border-radius: 15px;

        svg {
          width: 18px;
        }
      }

      p {
        color: #666666;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.title {
  margin-top: 15px;
  color: #404040;
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  margin-bottom: 10px;
  font-weight: 600;

  span {
    color: #3d734a;
  }
}

.subtitle {
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  text-align: center;
}

.alertTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #404040;
  margin-bottom: 5px;
}

.alertSubtitle {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 25px;
}

.footer {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: animateTopTop 0.5s both;

  @media screen and (min-width: 992px) {
  }
}

@keyframes animateTopTop {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
