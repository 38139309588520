.ecleticaItems {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4d4d4d;
  }

  .item {
    display: flex;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #e6e6e6;
    }

    .picture {
      min-width: 58px;
      min-height: 58px;
      position: relative;
      margin-right: 15px;
      border-radius: 15px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    .noImage {
      min-width: 58px;
      min-height: 58px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(112, 112, 112, 0.1);

      svg {
        width: 20px;

        path {
          fill: #aaa;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      flex: 1;

      .qty {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #666666;
      }

      .itemName {
        font-size: 14px;
        line-height: 18px;
        color: #666666;
        margin-left: 5px;
      }

      .total {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        .value {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          margin-bottom: 10px;
        }

        .edit,
        .remove {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .remove {
          margin-left: 10px;
        }

        .quantitySpinner {
          border: 1px solid rgba(0, 0, 0, 0.17);
          border-radius: 200px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;
          margin-right: 10px;

          @media screen and (max-width: 380px) {
            width: 85px;
          }

          .minus,
          .plus {
            padding: 0 15px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media screen and (max-width: 380px) {
              padding: 0 10px;
            }

            &.disabled {
              cursor: default;
              opacity: 0.5;
            }
          }

          .quantity {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .size {
        display: flex;
        align-items: center;
        margin-top: 10px;

        p {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666666;
        }

        span {
          font-size: 12px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.5);
        }

        .price {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #666666;
          margin-left: 5px;
        }
      }

      .questions {
        margin-top: 10px;

        .question {
          display: flex;
          flex-direction: column;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          p {
            font-weight: 600;
            font-size: 13px;
            color: #666666;
          }

          span {
            font-size: 12px;
            line-height: 15px;
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .extras {
        margin-top: 10px;

        p {
          font-weight: 600;
          font-size: 13px;
          color: #666666;
        }

        .extra {
          font-size: 12px;
          line-height: 15px;
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .observation {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba(102, 102, 102, 0.7);
        margin-top: 10px;
      }
    }
  }
}

.netLogicItems {
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4d4d4d;
  }

  .item {
    display: flex;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid #e6e6e6;
    }

    .picture {
      min-width: 58px;
      min-height: 58px;
      position: relative;
      margin-right: 15px;
      border-radius: 15px;
      overflow: hidden;

      img {
        object-fit: cover;
      }
    }

    .noImage {
      min-width: 58px;
      min-height: 58px;
      background: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(112, 112, 112, 0.1);

      svg {
        width: 20px;

        path {
          fill: #aaa;
        }
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      flex: 1;

      .qty {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #666666;
      }

      .itemName {
        font-size: 14px;
        line-height: 18px;
        color: #666666;
        margin-left: 5px;
      }

      .total {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        .value {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #666666;
          margin-bottom: 10px;
        }

        .edit,
        .remove {
          cursor: pointer;
          display: flex;
          align-items: center;
        }

        .remove {
          margin-left: 10px;
        }

        .quantitySpinner {
          border: 1px solid rgba(0, 0, 0, 0.17);
          border-radius: 200px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 30px;
          margin-right: 10px;

          @media screen and (max-width: 380px) {
            width: 85px;
          }

          .minus,
          .plus {
            padding: 0 15px;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media screen and (max-width: 380px) {
              padding: 0 10px;
            }

            &.disabled {
              cursor: default;
              opacity: 0.5;
            }
          }

          .quantity {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .size {
        display: flex;
        align-items: center;
        margin-top: 10px;

        p {
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: #666666;
        }

        span {
          font-size: 12px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.5);
        }

        .price {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #666666;
          margin-left: 5px;
        }
      }

      .questions {
        margin-top: 10px;

        .question {
          display: flex;
          flex-direction: column;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          p {
            font-weight: 600;
            font-size: 13px;
            color: #666666;
          }

          span {
            font-size: 12px;
            line-height: 15px;
            margin-top: 5px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

      .extras {
        margin-top: 10px;

        p {
          font-weight: 600;
          font-size: 13px;
          color: #666666;
        }

        .extra {
          font-size: 12px;
          line-height: 15px;
          margin-top: 5px;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .observation {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: rgba(102, 102, 102, 0.7);
        margin-top: 10px;
      }
    }
  }
}

.removeProduct {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.75);
    margin-bottom: 20px;
  }

  span {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 25px;
  }
}

.addItems {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
